/*
Copyright 2022 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/

// Use the AEM Headless SDK to make the GraphQL requests
import AEMHeadless from "@adobe/aem-headless-client-js";

// environment variable for configuring the headless client
const {
  REACT_APP_HOST_URI,
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_USE_PROXY,
  REACT_APP_AUTH_METHOD,
  REACT_APP_DEV_TOKEN,
  REACT_APP_BASIC_AUTH_USER,
  REACT_APP_BASIC_AUTH_PASS,
} = process.env;

// In a production application the serviceURL should be set to the production AEM Publish environment
// In development the serviceURL can be set to '/' which will be a relative proxy is used (see ../authMethods.js) to avoid CORS issues

const serviceURL = REACT_APP_USE_PROXY === "true" ? "/" : REACT_APP_HOST_URI;
let serviceToken;

// Get authorization based on environment variables
// authorization is not needed when connecting to Publish environments
const setAuthorization = async () => {
  if (REACT_APP_AUTH_METHOD === "basic") {
    return [REACT_APP_BASIC_AUTH_USER, REACT_APP_BASIC_AUTH_PASS];
  } else if (REACT_APP_AUTH_METHOD === "dev-token") {
    return REACT_APP_DEV_TOKEN;
  } else if ( REACT_APP_AUTH_METHOD === "service-token" ) {
    if ( !serviceToken ) {
        const resp = await fetch("/api/v1/web/securbank/getTechAccount");
        const json = await resp.json();
        serviceToken = json.token;
    }
    return serviceToken;
  } else {
    // no authentication set
    return;
  }
};

// Prefix URLs with AEM Host
export function addAemHost(url) {
  if (url.startsWith("/")) {
    return new URL(url, REACT_APP_HOST_URI).toString();
  }

  return url;
}

let aemHeadlessClient;
export async function getHeadlessClient() {
  if ( !aemHeadlessClient ) {
    aemHeadlessClient = new AEMHeadless({
      serviceURL: serviceURL,
      endpoint: REACT_APP_GRAPHQL_ENDPOINT,
      auth: await setAuthorization(),
    });
  }
  return aemHeadlessClient;
}

export default getHeadlessClient;
